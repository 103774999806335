import { Swiper, SwiperSlide } from "swiper/react"
import React from "react"
import PropTypes from "prop-types"

const TestimonialsSlider = ({ testimonials, autoHeight }) => {
  return !testimonials ? null : (
    <Swiper navigation autoHeight={autoHeight} className="container mx-auto">
      {testimonials.map(testimonial => (
        <SwiperSlide key={testimonial.content}>
          <div className="px-10 py-5 md:px-20 md:py-14">
            <p className="text-gray-50 font-medium text-lg text-center md:leading-9 md:text-2xl">
              {testimonial.content || ""}
            </p>
            <p className="text-gray-50 text-center font-medium text-lg mt-8">
              {testimonial.author || ""}
            </p>
            {!!testimonial.authorInfo && (
              <p className="text-gray-50 text-center font-light text-lg">
                {testimonial.authorInfo}
              </p>
            )}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

TestimonialsSlider.defaultProps = {
  autoHeight: true,
}

TestimonialsSlider.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      author: PropTypes.string,
      authorInfo: PropTypes.string,
    })
  ),
  autoHeight: PropTypes.bool,
}

export default TestimonialsSlider
